<template>
  <section class="invoice-preview-wrapper">
    <asset-options v-if="user.role != 'SIMPlVI'" :myId="myId" @event-block="openModalSetBlock"
                   @event-criterion="toVirtualFence" @event-point="toPoint" :has-block="hasBlock"/>
    <b-row class="justify-content-between">
      <b-col class="d-flex align-items-center mb-2">
        <feather-icon v-if="plan && plan.id" :icon="assetPlanIcon" class="text-primary mr-50" size="16"/>
        <p class="m-0 text-primary font-weight-bold text-header flex-fill">{{ plate != '' ? plate : chassisNumber }}</p>
        <!--                <p class="m-0 text-header font-weight-bold">{{ $t('Modem Identifier') }}: {{ deviceModemIdentifier }}</p>-->

      </b-col>
    </b-row>
    <b-row>
      <b-col xl="6" md="6" sm="12">

        <card-info heightImg="198px" :customerName="customerName"
                   :model="`${modelName} - ${modelYear}`" :chassis="`${plate ? plate : chassisNumber}`"
                   :status="`${lastWorkStatus ? lastWorkStatus : `noconnection`}`" :urlImage="imageicon"
                   :is-blocked="isBlocked" :dealerName="dealer" :panic="panic" :last-connection="lastConnectionTime"
                   :modem-identifier="deviceModemIdentifier"/>
        <b-card style="height: 550px;">
          <div class="d-flex justify-content-between align-items-center mb-1">
            <h5 class="my-0 custom-h5">{{ $t('Location') }} <span class="custom-h5">{{ $t('Last Position') }}:
                                {{ lastPositionTime }}</span></h5>
            <b-button title="Ir para o Google Maps" size="sm" variant="info"
                      @click="toGoogleMap()">
              <feather-icon icon="MapPinIcon" size="16"/>
            </b-button>
          </div>
          <l-map ref="myMap" id="myMap" :style="[{ maxHeight: mapMaxHeight }, { minHeight: mapMinHeight }]"
                 :zoom="zoom" :center="center">
            <l-tile-layer :url="url"></l-tile-layer>
            <l-control>
              <!-- size -->
              <b-button-group size="sm">
                <b-button variant="light" :pressed="btnMap" @click="chooseLayer('map')">
                  Map
                </b-button>
                <b-button variant="light" :pressed="btnGround" @click="chooseLayer('ground')">
                  Ground
                </b-button>
                <b-button variant="light" :pressed="btnHybrid" @click="chooseLayer('hybrid')">
                  Hybrid
                </b-button>
              </b-button-group>
            </l-control>
            <l-marker v-if="latLong != [0, 0]" :lat-lng="latLong">
              <l-icon :class-name="`someExtraClass asset-${lastWorkStatus} `" :icon-size="dynamicSize"
                      :tooltip-anchor="dynamicTooltip" :popup-anchor="dynamicPopup" :icon-anchor="dynamicAnchor">
                <img class="with-icon" :src="imageicon">
              </l-icon>
            </l-marker>
          </l-map>
        </b-card>
      </b-col>
      <b-col xl="6" md="6" sm="12">
        <status-card :dataWorkStatus="dataWorkStatus" :assetId="myId"/>
        <b-row v-show="assetType != 28">
          <b-col sm="12">

            <h5 class="m-0 font-weight-bold">{{ $t('Last Telemetry') }}: {{ lastConnectionTime }}</h5>
          </b-col>
          <b-col xl="4" md="4" sm="6" v-show="velocity">
            <simple-indicator-card :title="$t('Speed')" icon="ActivityIcon" :statistic="`${velocity}`"
                                   statistic-title="Km/h" color="info"/>
          </b-col>
          <b-col xl="4" md="4" sm="6" v-show="telemetryParameters.indexOf('hor') > -1 && horimeter">
            <simple-indicator-card :title="$t('Horimeter')" icon="ClockIcon" :statistic="`${horimeter}`"
                                   :statistic-title="$t('Hours')" color="info"/>
          </b-col>
          <b-col xl="4" md="4" sm="6" v-show="telemetryParameters.indexOf('hrb') > -1 && horimeterB">
            <simple-indicator-card :title="$t('Horimeter B')" icon="ClockIcon" :statistic="`${horimeterB}`"
                                   :statistic-title="$t('Hours')" color="info"/>
          </b-col>
          <b-col xl="4" md="4" sm="6" v-show="telemetryParameters.indexOf('hod') > -1 && odometer">
            <simple-indicator-card :title="$t('Odometer')" icon="AirplayIcon" :statistic="`${odometer}`"
                                   statistic-title="Km" color="info"/>
          </b-col>
          <b-col xl="4" md="4" sm="6" v-show="telemetryParameters.indexOf('cbc') > -1 && consumer">
            <simple-indicator-card :title="$t('Consumption')" icon="DropletIcon" :statistic="`${consumer}`"
                                   statistic-title="L/h" color="info"/>
          </b-col>
          <b-col xl="4" md="4" sm="6" v-show="telemetryParameters.indexOf('tpa') > -1 && engine">
            <simple-indicator-card :title="$t('Engine')" icon="ThermometerIcon" :statistic="`${engine}`"
                                   statistic-title="ºC" color="info"/>
          </b-col>
          <b-col xl="4" md="4" sm="6" v-show="telemetryParameters.indexOf('tpt') > -1 && transmission">
            <simple-indicator-card :title="$t('Transmission')" icon="ThermometerIcon"
                                   :statistic="`${transmission}`" statistic-title="ºC" color="info"/>
          </b-col>
          <b-col xl="4" md="4" sm="6" v-show="telemetryParameters.indexOf('toh') > -1 && oil">
            <simple-indicator-card :title="$t('Hydraulic oil')" icon="ThermometerIcon" :statistic="`${oil}`"
                                   statistic-title="ºC" color="info"/>
          </b-col>
          <b-col xl="4" md="4" sm="6" v-show="mta">
            <simple-indicator-card :title="$t('Max Temp Engine')" icon="ThermometerIcon" :statistic="`${mta}`"
                                   statistic-title="ºC" color="info"/>
          </b-col>
          <b-col xl="4" md="4" sm="6" v-show="mcc">
            <simple-indicator-card :title="$t('Max Consumption')" icon="DropletIcon" :statistic="`${mcc}`"
                                   statistic-title="L/h" color="info"/>
          </b-col>
          <b-col xl="4" md="4" sm="6" v-show="mth">
            <simple-indicator-card :title="$t('Max Temp Hydraulic oil')" icon="ThermometerIcon"
                                   :statistic="`${mth}`" statistic-title="ºC" color="info"/>
          </b-col>
          <b-col xl="4" md="4" sm="6" v-show="mtt">
            <simple-indicator-card :title="$t('Max Temp Transmission')" icon="ThermometerIcon"
                                   :statistic="`${mtt}`" statistic-title="ºC" color="info"/>
          </b-col>
          <b-col xl="4" md="4" sm="6" v-show="telemetryParameters.indexOf('btf') > -1 && btf">
            <simple-indicator-card :title="$t('Battery')" icon="BatteryChargingIcon"
                                   :statistic="`${$t('Low Battery')}`" statistic-title="⚠️" color="warning"/>
          </b-col>
          <b-col xl="4" md="4" sm="6" v-if="telemetryParameters.indexOf('cbt') > -1 && fuel > 0">
            <simple-gauge-indicator :fuel="fuel"/>
            <!--<simple-indicator-card title="Fuel" icon="DropletIcon" :statistic="`${fuel}`" statistic-title="%" color="info" />-->
          </b-col>

          <b-col xl="4" md="4" sm="6" v-show="rpm">
            <simple-indicator-card :title="$t('RPM')" icon="RotateCwIcon" :statistic="`${rpm}`"
                                   :statistic-title="$t('r/min')" color="info"/>
          </b-col>

          <b-col xl="4" md="4" sm="6" v-show="cgm">
            <simple-indicator-card :title="$t('Engine Load')" icon="ZapIcon" :statistic="`${cgm}`"
                                   statistic-title="%" color="info"/>
          </b-col>

          <!-- New params of telemetry -->
          <b-col xl="4" md="4" sm="6" v-show="pcb">
            <simple-indicator-card :title="$t('Fuel Pressure')" icon="InfoIcon" :statistic="`${pcb}`"
                                   :statistic-title="$t('kPa')" color="info"/>
          </b-col>

          <b-col xl="4" md="4" sm="6" v-show="pom">
            <simple-indicator-card :title="$t('Engine Oil Pressure')" icon="InfoIcon" :statistic="`${pom}`"
                                   :statistic-title="$t('kPa')" color="info"/>
          </b-col>

          <b-col xl="4" md="4" sm="6" v-show="ptb">
            <simple-indicator-card :title="$t('Turbo Pressure')" icon="InfoIcon" :statistic="`${ptb}`"
                                   :statistic-title="$t('kPa')" color="info"/>
          </b-col>
          <b-col xl="4" md="4" sm="6" v-show="tbt">
            <simple-indicator-card :title="$t('Battery Voltage')" icon="InfoIcon" :statistic="`${tbt}`"
                                   :statistic-title="$t('Volts')" color="info"/>
          </b-col>
          <b-col xl="4" md="4" sm="6" v-show="acl">
            <simple-indicator-card :title="$t('Acceleration')" icon="InfoIcon"
                                   :statistic="`${tryFixDecimalNumbers(acl)}`"
                                   :statistic-title="$t('G')" color="info"/>
          </b-col>
          <b-col xl="4" md="4" sm="6" v-show="kmh">
            <simple-indicator-card :title="$t('Speed')" icon="InfoIcon" :statistic="`${kmh}`"
                                   :statistic-title="$t('km/H')" color="info"/>
          </b-col>

          <b-col xl="4" md="4" sm="6" v-show="soc">
            <simple-indicator-card :title="$t('Power Battery Charge')" icon="InfoIcon" :statistic="`${soc}`"
                                   :statistic-title="$t('%')" color="info"/>
          </b-col>
          <b-col xl="4" md="4" sm="6" v-show="soh">
            <simple-indicator-card :title="$t('Power Battery Life')" icon="InfoIcon" :statistic="`${soh}`"
                                   :statistic-title="$t('%')" color="info"/>
          </b-col>
          <b-col xl="4" md="4" sm="6" v-show="ttb">
            <simple-indicator-card :title="$t('Power Battery Volts')" icon="InfoIcon" :statistic="`${ttb}`"
                                   :statistic-title="$t('Volts')" color="info"/>
          </b-col>
          <b-col xl="4" md="4" sm="6" v-show="tpb">
            <simple-indicator-card :title="$t('Power Battery Temperature')" icon="InfoIcon"
                                   :statistic="`${tpb}`" :statistic-title="$t('ºC')" color="info"/>
          </b-col>
          <b-col xl="4" md="4" sm="6" v-show="tom">
            <simple-indicator-card :title="$t('Engine oil temperature')" icon="ThermometerIcon"
                                   :statistic="`${tom}`" :statistic-title="$t('ºC')" color="info"/>
          </b-col>
          <b-col xl="4" md="4" sm="6" v-show="ctr">
            <simple-indicator-card :title="$t('Counter')" icon="InfoIcon" :statistic="`${ctr}`"
                                   :statistic-title="$t('Pulses')" color="info"/>
          </b-col>
          <b-col xl="4" md="4" sm="6" v-show="!!tct && tct > 1">
            <simple-indicator-card :title="$t('Active failure')" :icon="iconForPanelFailureCode(tct)"
                                   statistic=""
                                   :color="colorForPanelFailureCode(tct)"/>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="6" md="6" sm="12">
        <b-row v-show="assetType == 28 && deviceId">
          <b-col md="12">
            <card-send-commands :model="`${modelName}`" :identifier="`${plate != '' ? plate : chassisNumber}`"
                                :input01="in1" :input02="in2" :input03="in3" :input04="in4" :output01="out1"
                                :output02="out2"
                                :output03="out3" :output04="out4" :inputRange01="inputRange01"
                                :isLoadingOut1="isLoadingOut1"
                                :ssd="ssd" :sse="sse" :isLoadingGlobal="isLoadingGlobal" :isLoadingOut2="isLoadingOut2"
                                :isLoadingOut3="isLoadingOut3" :isLoadingRange01="isLoadingRange01"
                                @emit-send-command="handleSendCommand"/>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-card v-if="user.role != 'SIMPlVI'" no-body class="p-1">

      <b-card-body>
        <b-row class="mb-3 ml-25">
          <b-col cols="12" md="4" class="mb-md-0 mb-3">
            <label>{{ $t('Choose the Date') }}</label>
            <flat-pickr v-model="date" class="form-control"/>
          </b-col>
        </b-row>

        <b-row v-show="noMatchin" class=" ml-25">
          <b-col cols="12" md="6" class="mb-md-0 mb-3 ml-25 pt-2">
            <h4>{{ $t('No matching records found') }}</h4>
          </b-col>
        </b-row>

        <b-row v-if="timeConnectionVel.length > 0 && velHistory.length > 0">
          <!-- Inicio componente CompleteIndicator -->
          <b-col xl="12" md="12" sm="12">
            <complete-indicator-card unity="Km/h" :title="$t('Speed')" icon="ActivityIcon"
                                     :axisX="timeConnectionVel" :axisY="velHistory" :temperature="`${velocity}`"/>
          </b-col>
        </b-row>

        <b-row v-if="timeConnectionRpm.length > 0 && rpmHistory.length > 0">
          <!-- Inicio componente CompleteIndicator -->
          <b-col xl="12" md="12" sm="12">
            <complete-indicator-card unity="r/min" :title="$t('RPM')" icon="RotateCwIcon"
                                     :axisX="timeConnectionRpm" :axisY="rpmHistory" :temperature="`${rpm}`"/>
          </b-col>
        </b-row>

        <b-row v-if="timeConnectionEngine.length > 0 && engineHistory.length > 0">
          <!-- Inicio componente CompleteIndicator -->
          <b-col xl="12" md="12" sm="12">
            <complete-indicator-card unity="ºC" :title="$t('Engine Temperature')" icon="ThermometerIcon"
                                     :axisX="timeConnectionEngine" :axisY="engineHistory" :temperature="`${engine}`"/>
          </b-col>
        </b-row>

        <b-row v-if="timeConnectionOil.length > 0 && oilHistory.length > 0">
          <!-- Inicio componente CompleteIndicator -->
          <b-col xl="12" md="12" sm="12">
            <complete-indicator-card unity="ºC" :title="$t('Hydraulic Oil Temperature')" icon="ThermometerIcon"
                                     :axisX="timeConnectionOil" :axisY="oilHistory" :temperature="`${oil}`"/>
          </b-col>
        </b-row>

        <b-row v-if="timeConnectionTransmission.length > 0 && transmissionHistory.length > 0">
          <!-- Inicio componente CompleteIndicator -->
          <b-col xl="12" md="12" sm="12">
            <complete-indicator-card unity="ºC" :title="$t('Transmission Temperature')" icon="ThermometerIcon"
                                     :axisX="timeConnectionTransmission" :axisY="transmissionHistory"
                                     :temperature="`${transmission}`"/>
          </b-col>
        </b-row>

        <b-row v-if="timeConnectionCbc.length > 0 && cbcHistory.length > 0">
          <!-- Inicio componente CompleteIndicator -->
          <b-col xl="12" md="12" sm="12">
            <complete-indicator-card unity="L/h" :title="$t('Average Fuel Consumption')" icon="DropletIcon"
                                     :axisX="timeConnectionCbc" :axisY="cbcHistory" :temperature="`${consumer}`"/>
          </b-col>
        </b-row>

        <b-row v-if="timeConnectionCbt.length > 0 && cbtHistory.length > 0">
          <!-- Inicio componente CompleteIndicator -->
          <b-col xl="12" md="12" sm="12">
            <complete-indicator-card unity="%" :title="$t('Level Fuel')" icon="DropletIcon"
                                     :axisX="timeConnectionCbt" :axisY="cbtHistory" :temperature="`${fuel}`"/>
          </b-col>
        </b-row>


        <!-- New charts -->
        <b-row v-if="timeConnectionPcb.length > 0 && pcbHistory.length > 0">
          <!-- Inicio componente CompleteIndicator -->
          <b-col xl="12" md="12" sm="12">
            <complete-indicator-card unity="kPa" :title="$t('Fuel Pressure')" icon="InfoIcon"
                                     :axisX="timeConnectionPcb" :axisY="pcbHistory" :temperature="`${pcb}`"/>
          </b-col>
        </b-row>

        <b-row v-if="timeConnectionPom.length > 0 && pomHistory.length > 0">
          <!-- Inicio componente CompleteIndicator -->
          <b-col xl="12" md="12" sm="12">
            <complete-indicator-card unity="kPa" :title="$t('Engine Oil Pressure')" icon="InfoIcon"
                                     :axisX="timeConnectionPom" :axisY="pomHistory" :temperature="`${pom}`"/>
          </b-col>
        </b-row>

        <b-row v-if="timeConnectionPtb.length > 0 && ptbHistory.length > 0">
          <!-- Inicio componente CompleteIndicator -->
          <b-col xl="12" md="12" sm="12">
            <complete-indicator-card unity="kPa" :title="$t('Turbo Pressure')" icon="InfoIcon"
                                     :axisX="timeConnectionPtb" :axisY="ptbHistory" :temperature="`${ptb}`"/>
          </b-col>
        </b-row>

        <b-row v-if="timeConnectionTbt.length > 0 && tbtHistory.length > 0">
          <!-- Inicio componente CompleteIndicator -->
          <b-col xl="12" md="12" sm="12">
            <complete-indicator-card unity="Volts" :title="$t('Battery Voltage')" icon="InfoIcon"
                                     :axisX="timeConnectionTbt" :axisY="tbtHistory" :temperature="`${tbt}`"/>
          </b-col>
        </b-row>

        <b-row v-if="timeConnectionTtb.length > 0 && ttbHistory.length > 0">
          <!-- Inicio componente CompleteIndicator -->
          <b-col xl="12" md="12" sm="12">
            <complete-indicator-card unity="Volts" :title="$t('Battery Voltage')" icon="InfoIcon"
                                     :axisX="timeConnectionTtb" :axisY="ttbHistory" :temperature="`${ttb}`"/>
          </b-col>
        </b-row>

        <b-row v-if="timeConnectionAcl.length > 0 && aclHistory.length > 0">
          <!-- Inicio componente CompleteIndicator -->
          <b-col xl="12" md="12" sm="12">
            <complete-indicator-card unity="G" :title="$t('Acceleration')" icon="InfoIcon"
                                     :axisX="timeConnectionAcl" :axisY="aclHistory"
                                     :temperature="`${tryFixDecimalNumbers(acl)}`"/>
          </b-col>
        </b-row>

        <b-row v-if="timeConnectionKmh.length > 0 && kmhHistory.length > 0">
          <!-- Inicio componente CompleteIndicator -->
          <b-col xl="12" md="12" sm="12">
            <complete-indicator-card unity="Km/h" :title="$t('Speed')" icon="InfoIcon"
                                     :axisX="timeConnectionKmh" :axisY="kmhHistory" :temperature="`${kmh}`"/>
          </b-col>
        </b-row>

        <b-row v-if="timeConnectionCgm.length > 0 && cgmHistory.length > 0">
          <!-- Inicio componente CompleteIndicator -->
          <b-col xl="12" md="12" sm="12">
            <complete-indicator-card unity="%" :title="$t('Engine Load')" icon="InfoIcon"
                                     :axisX="timeConnectionCgm" :axisY="cgmHistory" :temperature="`${cgm}`"/>
          </b-col>
        </b-row>
        <b-row v-if="timeConnectionSoc.length > 0 && socHistory.length > 0">
          <!-- Inicio componente CompleteIndicator -->
          <b-col xl="12" md="12" sm="12">
            <complete-indicator-card unity="%" :title="$t('Power Battery Charge')" icon="InfoIcon"
                                     :axisX="timeConnectionSoc" :axisY="socHistory" :temperature="`${soc}`"/>
          </b-col>
        </b-row>
        <b-row v-if="timeConnectionTpb.length > 0 && tpbHistory.length > 0">
          <!-- Inicio componente CompleteIndicator -->
          <b-col xl="12" md="12" sm="12">
            <complete-indicator-card unity="ºC" :title="$t('Power Battery Temperature')" icon="InfoIcon"
                                     :axisX="timeConnectionTpb" :axisY="tpbHistory" :temperature="`${tpb}`"/>
          </b-col>
        </b-row>
        <b-row v-if="timeConnectionTom.length > 0 && tomHistory.length > 0">
          <!-- Inicio componente CompleteIndicator -->
          <b-col xl="12" md="12" sm="12">
            <complete-indicator-card unity="ºC" :title="$t('Engine oil temperature')" icon="ThermometerIcon"
                                     :axisX="timeConnectionTom" :axisY="tomHistory" :temperature="`${tom}`"/>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-modal ref="modal-block" size="md" hide-footer centered>
      <div class="d-block text-center">
        <h3>Você deseja {{ isBlocked ? 'desativar' : 'ativar' }} o bloqueio?</h3>
      </div>
      <div class="d-flex justify-content-center text-center">
        <button type="button" class="btn btn-secondary mr-50" @click="hideModalSetBlock">
          Fechar
        </button>
        <button type="button" class="btn btn-primary ml-50" @click="toogleBlock">
          {{ isBlocked ? 'Desativar' : 'Ativar' }} Bloqueio
        </button>
      </div>
    </b-modal>

  </section>
</template>

<script>
import {
  ref,
  onUnmounted
} from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  VBToggle,
  BImg,
  BButtonGroup,
  BModal,
} from 'bootstrap-vue'
import Logo from '@core/layouts/components/Logo.vue'
import Ripple from 'vue-ripple-directive'
import assetStoreModule from '../assetStoreModule'

import SimpleIndicatorCard from '../asset-preview/indicators/SimpleIndicatorCard.vue'
import SimpleGaugeIndicator from '../asset-preview/indicators/SimpleGaugeIndicator.vue'
import CompleteIndicatorCard from '../asset-preview/indicators/CompleteIndicatorCard.vue'
import CardInfo from '../asset-preview/indicators/CardInfo.vue'
import CardSendCommands from "@/views/main/asset/asset-preview/indicators/CardSendCommands.vue";
import StatusCard from '../asset-preview/indicators/StatusCard.vue'

import AssetOptions from "../../components/AssetOptions.vue";

import flatPickr from 'vue-flatpickr-component'

import {
  LMap,
  LTileLayer,
  LMarker,
  LIcon,
  LPopup,
  LControl,
} from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'
import {
  latLng,
  icon,
  Icon
} from 'leaflet'
import L from 'leaflet';

import BCardCode from '@core/components/b-card-code/BCardCode.vue'

import signalrHub from '@core/signalr/signalrHub.js'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    Logo,
    BImg,
    BButtonGroup,
    flatPickr,
    BModal,

    SimpleIndicatorCard,
    SimpleGaugeIndicator,
    CompleteIndicatorCard,
    CardInfo,
    CardSendCommands,

    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LPopup,
    LControl,

    BCardCode,
    StatusCard,
    AssetOptions
  },
  data() {
    return {
      deviceModemIdentifier: '',
      showModal: false,
      btnGround: false,
      btnMap: true,
      btnHybrid: false,
      date: ``,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 4,
      center: [-10.1500034, -67.9163393],
      icon: L.icon({

        iconSize: [32, 37],
        iconAnchor: [16, 37]
      }),
      staticAnchor: [16, 37],

      iconSize: 40,
      marker: {
        latLong: [0, 0],

      },
      mapHeight: '45vh',
      mapMaxHeight: '470px',
      mapMinHeight: '470px',
      teste: '50',
      mostrar: false,

      //Data fetchAsset
      chassisNumber: "",
      color: "",
      customerId: "",
      engineNumber: null,
      id: "",
      modelName: "",
      modelYear: 0,
      nickname: "",
      plate: "",
      assetType: ``,
      customerName: ``,
      dealer: '',
      hasBlock: false,

      //Data fetchAssetSummary
      lastConnectionTime: '',
      lastPositionTime: '',
      lastTelemetryTime: '',
      lastTelemetry: '',
      lastWorkStatus: '',
      latLong: [0, 0],

      //Data fetchAssetTelemetry

      timeConnectionRpm: [],
      rpmHistory: [],


      telemetryParameters: [],
      velocity: '',
      timeConnectionVel: [],
      velHistory: [],
      horimeter: '',
      horimeterB: '',
      odometer: '',
      consumer: '',
      engine: '',
      timeConnectionEngine: [],
      engineHistory: [],
      transmission: '',
      timeConnectionTransmission: [],
      transmissionHistory: [],
      oil: '',
      timeConnectionOil: [],
      oilHistory: [],
      btf: '',
      fuel: 0,
      timeConnectionCbc: [],
      cbcHistory: [],
      timeConnectionCbt: [],
      cbtHistory: [],
      mta: '',
      mcc: '',
      mth: '',
      mtt: '',
      rpm: '',
      cgm: '',

      in1: false,
      in2: false,
      in3: false,
      in4: false,

      imageicon: '',
      loadingHistory: false,

      // Data fetchAssetWorkStatus
      //dataWorkStatus:[],

      deviceId: '',
      inputRange01: 0,
      out1: false,
      out2: false,
      out3: false,
      out4: false,

      oldInputRange01: 0,
      oldOldOut1: false,
      oldOut2: false,
      oldOut3: false,

      isLoadingOut1: false,
      isLoadingOut2: false,
      isLoadingOut3: false,
      isLoadingGlobal: false,
      isLoadingRange01: false,

      sse: false,
      ssd: false,

      panic: false,

      pcb: '',
      pom: '',
      ptb: '',
      tbt: '',
      acl: '',
      kmh: '',

      timeConnectionPcb: [],
      pcbHistory: [],
      pomHistory: [],
      timeConnectionPom: [],
      ptbHistory: [],
      timeConnectionPtb: [],
      tbtHistory: [],
      timeConnectionTbt: [],
      aclHistory: [],
      timeConnectionAcl: [],
      kmhHistory: [],
      timeConnectionKmh: [],
      cgmHistory: [],
      timeConnectionCgm: [],
      ttbHistory: [],
      timeConnectionTtb: [],
      socHistory: [],
      timeConnectionSoc: [],
      tpbHistory: [],
      timeConnectionTpb: [],
      tomHistory: [],
      timeConnectionTom: [],


      soc: "",
      soh: "",
      ttb: "",
      tpb: "",
      ctr: "",
      dtc: "",
      tct: "",
      tom: "",

      plan: null,

      planIcons: {
        1: "ZapIcon",
        2: "StarIcon",
        3: "AwardIcon"
      },

      waitingCommandResponse: false,
      isBlocked: false
    };
  },
  computed: {
    dynamicSize() {
      this.toltipAnchor = [27, 30];
      return [this.iconSize, this.iconSize * 1.15];
    },
    dynamicAnchor() {
      if (this.selectedIcon == true) {
        return [27, 30];
      } else {
        return [15, 13];
      }
    },
    dynamicTooltip() {
      if (this.selectedIcon == true) {
        return [25, 0];
      } else {
        return [7, -15];
      }
    },
    dynamicPopup() {
      if (this.selectedIcon == true) {
        return [2, -27];
      } else {
        return [-7, -13];
      }
    },

    noMatchin() {
      if (this.loadingHistory == false &&
          this.timeConnectionTransmission.length == 0 && this.transmissionHistory.length == 0 &&
          this.timeConnectionOil.length == 0 && this.oilHistory.length == 0 &&
          this.timeConnectionEngine.length == 0 && this.engineHistory.length == 0 &&
          this.timeConnectionVel.length == 0 && this.velHistory.length == 0 &&
          this.timeConnectionCbt.length == 0 && this.cbtHistory.length == 0 &&
          this.timeConnectionCbc.length == 0 && this.cbcHistory.length == 0 &&
          this.timeConnectionRpm.length == 0 && this.rpmHistory.length == 0 &&

          this.timeConnectionPcb.length == 0 &&
          this.pcbHistory.length == 0 &&
          this.pomHistory.length == 0 &&
          this.timeConnectionPom.length == 0 &&
          this.ptbHistory.length == 0 &&
          this.timeConnectionPtb.length == 0 &&
          this.tbtHistory.length == 0 &&
          this.timeConnectionTbt.length == 0 &&
          this.aclHistory.length == 0 &&
          this.timeConnectionAcl.length == 0 &&
          this.kmhHistory.length == 0 &&
          this.timeConnectionKmh.length == 0 &&
          this.cgmHistory.length == 0 &&
          this.timeConnectionCgm.length == 0 &&
          this.ttbHistory.length == 0 && this.timeConnectionTtb.length == 0 &&
          this.socHistory.length == 0 && this.timeConnectionSoc == 0 &&
          this.tpbHistory.length == 0 && this.timeConnectionTpb == 0 &&
          this.tomHistory.length == 0 && this.timeConnectionTom == 0
      ) {
        return true
      }
      return false
    },
    assetPlanIcon() {
      if (!this.plan?.id) return undefined
      return this.planIcons[this.plan.id]
    },
  },
  created() {
    const ASSET_APP_STORE_MODULE_NAME = 'app-asset'
    const dts = new Date();

    dts.setHours(0);
    dts.setMinutes(0);

    const dtf = new Date();
    dtf.setHours(23);
    dtf.setMinutes(59);

    // Register module
    if (!store.hasModule(ASSET_APP_STORE_MODULE_NAME)) store.registerModule(ASSET_APP_STORE_MODULE_NAME, assetStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ASSET_APP_STORE_MODULE_NAME)) store.unregisterModule(ASSET_APP_STORE_MODULE_NAME)
    })

    // fetchAsset
    store
        .dispatch('app-asset/fetchAsset', {
          id: router.currentRoute.params.id

        })
        .then(response => {

          response.data.devices.forEach(device => {
            this.deviceModemIdentifier = this.deviceModemIdentifier + ` ${device.modemIdentifier}`
          })
          this.dealer = response.data.dealerName
          this.customerName = response.data.ownerName

          this.chassisNumber = response.data.chassisNumber;
          this.color = response.data.color;

          this.engineNumber = response.data.engineNumber;

          this.modelName = response.data.modelIdName != '' ? response.data.modelIdName : response.data.modelName;
          this.modelYear = response.data.modelYear;
          this.nickname = response.data.nickname;
          this.plate = response.data.plate;
          this.assetType = response.data.assetType;
          this.hasBlock = response.data.hasBlock

          this.imageicon = this.resolveIcon(this.assetType, response.data.iconUrl);

          const identifier = response.data.plate ? response.data.plate : response.data.chassisNumber
          //this.getDeviceId(identifier)
          this.deviceId = response.data.devices[0].id

          this.plan = response.data.plan
        })
        .catch((e) => {


        })

    this.fetchAssetSummary()


    // fetchAssetTelemetryParameters
    store.dispatch('app-asset/fetchAssetTelemetryParameters', {
      id: router.currentRoute.params.id
      //id: '365c7968-6bfd-4310-a6f8-b6f9fe2ffd2d'
    })
        .then(res => {
          this.telemetryParameters = res.data;
          this.date = `${dts.getFullYear()}-${dts.getMonth() + 1}-${dts.getDate()}`;

        })
        .catch(error => {

        })

  },
  mounted() {
    this.registerEventBusListeners();
    if (localStorage.layerPreview) {
      this.chooseLayer(localStorage.layerPreview)
    }


  },
  beforeDestroy() {

    this.deregisterEventBusListeners();
    clearInterval(this.interval);
  },

  watch: {
    date(newQuestion, oldQuestion) {
      this.fetchHistoric()
    }
  },
  methods: {

    openModalSetBlock() {
      this.$refs['modal-block'].show()
    },
    hideModalSetBlock() {
      this.$refs['modal-block'].hide()
    },
    toogleBlock() {

      let command = {
        deviceId: this.deviceId,
        commandTypeId: 7,
        data: `${1}=${this.isBlocked ? 0 : 1}`
      }
      store.dispatch('app-asset/sendDeviceTelecommand', command)
          .then(response => {
            this.msgDialog("Comando enviado!", "info", "")
            // if (port == 1) this.isLoadingOut1 = false
            // if (port == 2) this.isLoadingOut2 = false
            // if (port == 3) this.isLoadingOut3 = false
          })
          .catch(error => {

          })
      this.hideModalSetBlock()
      //this.isBlocked = !this.isBlocked

    },


    tryFixDecimalNumbers(number) {
      try {
        return parseFloat(number.toFixed(2));
      } catch (error) {
        return number;
      }
    },
    colorForPanelFailureCode(failureType) {
      if (!this.plan?.id || this.plan.id <= 1) return 'info'

      if (failureType == 2) return 'warning'
      if (failureType == 3) return 'danger'
      return 'info'
    },
    iconForPanelFailureCode(failureType) {
      console.log("failure", failureType, "plan", this.plan)
      if (!this.plan?.id || this.plan.id <= 1) return 'InfoIcon'

      if (failureType == 2) return 'AlertTriangleIcon'
      if (failureType == 3) return 'XOctagonIcon'
      return 'InfoIcon'
    },

    toGoogleMap() {
      var url = "https://www.google.com/maps?q=" + this.latLong[0] + "," + this.latLong[1];
      window.open(url, '_blank');

    },

    msgDialog(msg, icon, text) {
      this.$swal({
        position: 'top-end',
        icon: icon,
        title: msg,
        text: text,
        showConfirmButton: false,
        timer: 1500 * 2,
        customClass: {
          confirmButton: 'btn btn-primary',
        },

        buttonsStyling: false,
      })
    },

    fetchAssetSummary() {
      store
          .dispatch('app-asset/fetchAssetSummary', {
            id: router.currentRoute.params.id

          })
          .then(response => {

            if (response.data.lastTelemetry != null) {

              if (this.waitingCommandResponse) {
                this.resetLoadings()
                this.msgDialog("Ocorreu um erro inesperado", "error", "Falha em comunicar com o satélite!")
                this.waitingCommandResponse = false
              }

              //Set values indicators
              if (response.data.lastTelemetry.pan) {
                this.panic = response.data.lastTelemetry.pan
              }


              if (response.data.lastkmh) {
                this.velocity = response.data.lastkmh
              }
              if (response.data.lastTelemetry.hor) {
                this.horimeter = response.data.lastTelemetry.hor
              }
              if (response.data.lastTelemetry.hrb) {
                this.horimeterB = response.data.lastTelemetry.hrb
              }
              if (response.data.lastTelemetry.hod) {
                this.odometer = response.data.lastTelemetry.hod
              }
              if (response.data.lastTelemetry.tpa) {
                this.engine = response.data.lastTelemetry.tpa
              }
              if (response.data.lastTelemetry.cbc) {
                this.consumer = response.data.lastTelemetry.cbc
              }
              if (response.data.lastTelemetry.tpt) {
                this.transmission = response.data.lastTelemetry.tpt
              }
              if (response.data.lastTelemetry.toh) {
                this.oil = response.data.lastTelemetry.toh
              }
              if (response.data.lastTelemetry.btf) {
                this.btf = response.data.lastTelemetry.btf
              }
              if (response.data.lastTelemetry.cbt) {
                this.fuel = response.data.lastTelemetry.cbt
              }
              if (response.data.lastTelemetry.mta) {
                this.mta = response.data.lastTelemetry.mta
              }
              if (response.data.lastTelemetry.mcc) {
                this.mcc = response.data.lastTelemetry.mcc
              }
              if (response.data.lastTelemetry.mth) {
                this.mth = response.data.lastTelemetry.mth
              }
              if (response.data.lastTelemetry.mtt) {
                this.mtt = response.data.lastTelemetry.mtt
              }

              if (response.data.lastTelemetry.rpm) {
                this.rpm = response.data.lastTelemetry.rpm
              }

              if (response.data.lastTelemetry.cgm) {
                this.cgm = response.data.lastTelemetry.cgm
              }

              // New Params

              if (response.data.lastTelemetry.ptb) {
                this.ptb = response.data.lastTelemetry.ptb
              }
              if (response.data.lastTelemetry.tbt) {
                this.tbt = response.data.lastTelemetry.tbt
              }
              if (response.data.lastTelemetry.acl) {
                this.acl = response.data.lastTelemetry.acl
              }
              if (response.data.lastTelemetry.kmh) {
                this.kmh = response.data.lastTelemetry.kmh
              }
              if (response.data.lastTelemetry.pcb) {
                this.pcb = response.data.lastTelemetry.pcb
              }
              if (response.data.lastTelemetry.pom) {
                this.pom = response.data.lastTelemetry.pom
              }

              if (response.data.lastTelemetry.soc) {
                this.soc = response.data.lastTelemetry.soc
              }
              if (response.data.lastTelemetry.soh) {
                this.soh = response.data.lastTelemetry.soh
              }
              if (response.data.lastTelemetry.ttb) {
                this.ttb = response.data.lastTelemetry.ttb
              }
              if (response.data.lastTelemetry.tpb) {
                this.tpb = response.data.lastTelemetry.tpb
              }
              if (response.data.lastTelemetry.tom) {
                this.tom = response.data.lastTelemetry.tom
              }
              if (response.data.lastTelemetry.ctr) {
                this.ctr = response.data.lastTelemetry.ctr
              }
              if (response.data.lastTelemetry.dtc) {
                this.dtc = response.data.lastTelemetry.dtc
              }
              if (response.data.lastTelemetry.tct) {
                this.tct = response.data.lastTelemetry.tct
              }


              //End new params

              if (response.data.lastTelemetry.in1 == false || response.data.lastTelemetry.in1) {
                this.in1 = response.data.lastTelemetry.in1
              }
              if (response.data.lastTelemetry.in2 == false || response.data.lastTelemetry.in2) {
                this.in2 = response.data.lastTelemetry.in2
              }
              if (response.data.lastTelemetry.in3 == false || response.data.lastTelemetry.in3) {
                this.in3 = response.data.lastTelemetry.in3
              }
              if (response.data.lastTelemetry.in4 == 1 || response.data.lastTelemetry.in4 == 0) {
                this.in4 = response.data.lastTelemetry.in4 == 0 ? false : true
              }
              if (response.data.lastTelemetry.ou1 == false || response.data.lastTelemetry.ou1 == 0 || response.data.lastTelemetry.ou1 == 1) {
                this.out1 = response.data.lastTelemetry.ou1
                this.oldOldOut1 = this.out1
                this.isBlocked = response.data.lastTelemetry.ou1 === 1
              }
              if (response.data.lastTelemetry.ou2 == false || response.data.lastTelemetry.ou2) {
                this.out2 = response.data.lastTelemetry.ou2
                this.oldOldOut2 = this.out2
              }
              if (response.data.lastTelemetry.ou3 == false || response.data.lastTelemetry.ou3) {
                this.out3 = response.data.lastTelemetry.ou3
                this.oldOldOut3 = this.out3
              }
              if (response.data.lastTelemetry.ou4) {
                this.inputRange01 = response.data.lastTelemetry.ou4
                this.oldInputRange01 = this.inputRange01
              }
            }

            this.lastConnectionTime = this.formatDate(response.data.lastConnectionTime);
            //this.lastPositionTime = new Date(response.data.lastPositionTime).toLocaleString().substring(0, 16);
            this.lastPositionTime = this.formatDate(response.data.lastPositionTime)
            //this.lastTelemetryTime= new Date(response.data.lastTelemetryTime);
            if (response.data.lastWorkStatus) this.lastWorkStatus = response.data.lastWorkStatus;
            if (response.data.lastLat && response.data.lastLat != 0 && response.data.lastLng && response.data.lastLng != 0) {
              this.latLong = [response.data.lastLat, response.data.lastLng]

              this.$nextTick(() => {
                this.$refs.myMap.mapObject.setView(this.latLong, 17);
                //this.$refs.myMap.mapObject.setView(this.latLong);

              })

            }

          })
          .catch((e) => {

          })
    },


    sendCommands(port, value) {
      this.isLoadingGlobal = true
      if (port == 1) this.isLoadingOut1 = true
      if (port == 2) this.isLoadingOut2 = true
      if (port == 3) this.isLoadingOut3 = true
      if (port == 4) this.isLoadingRange01 = true

      //let val = 0

      // if (port == 4) {
      //     val = value
      // } else {
      //     val = value == true ? 1 : 0
      // }

      let command = {
        deviceId: this.deviceId,
        commandTypeId: 7,
        data: `${port}=${value}`
      }


      store.dispatch('app-asset/sendDeviceTelecommand', command)
          .then(response => {

            // if (port == 1) this.isLoadingOut1 = false
            // if (port == 2) this.isLoadingOut2 = false
            // if (port == 3) this.isLoadingOut3 = false
          })
          .catch(error => {

          })
    },

    resetLoadings() {
      this.isLoadingGlobal = false
      this.isLoadingOut2 = false
      this.isLoadingOut3 = false
      this.isLoadingRange01 = false
      this.isLoadingOut1 = false
    },

    sendBulkDeviceTelecommand() {
      this.isLoadingGlobal = true

      let ou1 = this.out1 ? 1 : 0
      let ou2 = this.out2 ? 1 : 0
      let ou3 = this.out3 ? 1 : 0

      let command = {
        deviceId: this.deviceId,
        commands: [
          {
            commandTypeId: 7,
            data: `1=${ou1}`
          },
          {
            commandTypeId: 7,
            data: `2=${ou2}`
          },
          {
            commandTypeId: 7,
            data: `3=${ou3}`
          },
          {
            commandTypeId: 7,
            data: `4=${this.inputRange01}`
          }
        ]
      }
      this.waitingCommandResponse = true

      let self = this
      setTimeout(function () {
        self.fetchAssetSummary()
        console.log("Chamou apos 7 segundos")
      }, 330000);


      store.dispatch('app-asset/sendBulkDeviceTelecommand', command)
          .then(response => {
          })
          .catch(error => {

          })
    },

    handleInput01Clicked(checked) {
      this.out1 = !this.out1
      this.sendCommands(1, checked)
    },
    handleInput02Clicked(checked) {
      this.out2 = !this.out2
      this.sendCommands(2, checked)
    },
    handleInput03Clicked(checked) {
      this.out3 = !this.out3
      this.sendCommands(3, checked)
    },
    handleInput04Clicked(checked) {
    },
    handleInputRange01Clicked(val) {
      this.inputRange01 = Math.round(val)

      if (this.inputRange01 < 0 || this.inputRange01 > 100) {
        this.msgDialog('Input fora do range', 'info', "O intervalo deve ser de 0 a 100")
        return
      }

      this.sendCommands(4, this.inputRange01)
      //sendCommands(4, val)
    },

    handleSendCommand(val) {
      this.inputRange01 = Math.round(val.ou4)

      if (this.inputRange01 < 0 || this.inputRange01 > 100) {
        this.msgDialog('Input fora do range', 'info', "O intervalo deve ser de 0 a 100")
        return
      }

      this.out1 = val.ou1
      this.out2 = val.ou2
      this.out3 = val.ou3

      let command = {
        ou1: this.out1,
        ou2: this.out2,
        ou3: this.out3,
        ou4: this.inputRange01,
      }
      console.log("comando a ser enviado ", command)
      this.sendBulkDeviceTelecommand()
    },

    toVirtualFence() {
      var center = this.$refs.myMap.mapObject.getCenter()
      var zoom = this.$refs.myMap.mapObject.getZoom()


      router.push({
        name: 'fence-register',
        query: {
          criterion: {
            zoom: zoom,
            lat: center.lat,
            lng: center.lng
          }
        }
      })
    },
    toPoint() {
      var center = this.$refs.myMap.mapObject.getCenter()
      var zoom = this.$refs.myMap.mapObject.getZoom()


      router.push({
        name: 'point-register',
        query: {
          criterion: {
            zoom: zoom,
            lat: center.lat,
            lng: center.lng
          }
        }
      })
    },

    chooseLayer(url) {
      localStorage.layerPreview = url;

      if (url == `ground`) {
        this.btnGround = true
        this.btnMap = false
        this.btnHybrid = false
        this.url = "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}";
      }
      if (url == `map`) {
        var center = this.$refs.myMap.mapObject.getCenter()
        var zoom = this.$refs.myMap.mapObject.getZoom()

        this.btnGround = false
        this.btnMap = true
        this.btnHybrid = false
        this.url = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      }
      if (url == `hybrid`) {
        this.btnGround = false
        this.btnMap = false
        this.btnHybrid = true
        this.url = "https://2.aerial.maps.ls.hereapi.com/maptile/2.1/maptile/newest/hybrid.day/{z}/{x}/{y}/256/png8?lg=por&apiKey=U7wvT2IU0rFTeGwMoIpdf7JaQx5QH74SyhG4qiHy7Q8"
        ;
      }
      //else this.url = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
    },

    fetchHistoric() {
      const dts = new Date(this.date);


      dts.setHours(0);
      dts.setMinutes(0);
      dts.setDate(dts.getDate());

      const dtf = new Date(this.date);
      dtf.setHours(23);
      dtf.setMinutes(59);
      dtf.setDate(dtf.getDate());

      //RESET CHARTS
      this.timeConnectionEngine = [];
      this.engineHistory = [];
      this.timeConnectionVel = [];
      this.velHistory = [];
      this.timeConnectionTransmission = [];
      this.transmissionHistory = [];
      this.timeConnectionOil = [];
      this.oilHistory = [];
      this.timeConnectionCbc = [];
      this.cbcHistory = [];
      this.timeConnectionCbt = [];
      this.cbtHistory = [];

      this.timeConnectionRpm = [];
      this.rpmHistory = [];

      //New Params
      this.timeConnectionPcb = [];
      this.pcbHistory = [];
      this.pomHistory = [];
      this.timeConnectionPom = [];
      this.ptbHistory = [];
      this.timeConnectionPtb = [];
      this.tbtHistory = [];
      this.timeConnectionTbt = [];
      this.aclHistory = [];
      this.timeConnectionAcl = [];
      this.kmhHistory = [];
      this.timeConnectionKmh = [];
      this.cgmHistory = [];
      this.timeConnectionCgm = [];
      this.ttbHistory = []
      this.timeConnectionTtb = []
      this.socHistory = []
      this.timeConnectionSoc = []
      this.tpbHistory = []
      this.timeConnectionTpb = []
      this.tomHistory = []
      this.timeConnectionTom = []

      this.fetchAssetTelemetry(dts, dtf)
    },

    getDeviceId(assetIdentificator) {

      store.dispatch('app-asset/fetchDevices', {
        page: 1,
        perPage: 10,
        q: assetIdentificator

      }).then(response => {

        if (response && response.data.data.length > 0) {
          this.deviceId = response.data.data[0].id
        }
      });


    },

    fetchAssetTelemetry(dtS, dtE) {
      const startDate = new Date(Date.UTC(
          dtS.getFullYear(),
          dtS.getMonth(),
          dtS.getDate(),
          0, 0, 0
      )).toUTCString();
      
      const endDate = new Date(Date.UTC(
          dtS.getFullYear(),
          dtS.getMonth(),
          dtS.getDate(),
          23, 59, 59
      )).toUTCString();
        
      let params = []
      this.telemetryParameters.map(async (p, i) => {
        var page = 1
        var cf = false
        this.loadingHistory = true

        while (cf == false) {
          await store.dispatch('app-asset/fetchAssetTelemetry', {
            id: router.currentRoute.params.id,
            param: this.telemetryParameters[i],
            dtS: startDate,
            dtE: endDate,

            page: page
          }).then(response => {

            if (response && response.data.data.length > 0) {
              if (this.telemetryParameters[i] == 'tpa') {
                this.timeConnectionEngine = [];
                this.engineHistory = [];

                for (var j = 0; j < response.data.data.length; j++) {
                  this.timeConnectionEngine.push(this.getHoursAndMinutes(response.data.data[j].dateTime))
                  this.engineHistory.push(response.data.data[j].data.tpa)
                }
              }
              if (this.telemetryParameters[i] == 'kmh') {
                this.timeConnectionVel = [];
                this.velHistory = [];
                for (var j = 0; j < response.data.data.length; j++) {
                  this.timeConnectionVel.push(this.getHoursAndMinutes(response.data.data[j].dateTime))
                  this.velHistory.push(response.data.data[j].data.kmh)
                }
              }
              if (this.telemetryParameters[i] == 'tpt') {
                this.timeConnectionTransmission = [];
                this.transmissionHistory = [];
                for (var j = 0; j < response.data.data.length; j++) {
                  this.timeConnectionTransmission.push(this.getHoursAndMinutes(response.data.data[j].dateTime))
                  this.transmissionHistory.push(response.data.data[j].data.tpt)
                }

              }
              if (this.telemetryParameters[i] == 'toh') {
                this.timeConnectionOil = [];
                this.oilHistory = [];
                for (var j = 0; j < response.data.data.length; j++) {
                  this.timeConnectionOil.push(this.getHoursAndMinutes(response.data.data[j].dateTime))
                  this.oilHistory.push(response.data.data[j].data.toh)
                }

              }
              if (this.telemetryParameters[i] == 'cbc') {
                this.timeConnectionCbc = [];
                this.cbcHistory = [];
                for (var j = 0; j < response.data.data.length; j++) {
                  this.timeConnectionCbc.push(this.getHoursAndMinutes(response.data.data[j].dateTime))
                  this.cbcHistory.push(response.data.data[j].data.cbc)
                }
              }
              if (this.telemetryParameters[i] == 'cbt') {
                this.timeConnectionCbt = [];
                this.cbtHistory = [];
                for (var j = 0; j < response.data.data.length; j++) {
                  this.timeConnectionCbt.push(this.getHoursAndMinutes(response.data.data[j].dateTime))
                  this.cbtHistory.push(response.data.data[j].data.cbt)
                }

              }
              if (this.telemetryParameters[i] == 'rpm') {
                this.timeConnectionRpm = [];
                this.rpmHistory = [];
                for (var j = 0; j < response.data.data.length; j++) {
                  this.timeConnectionRpm.push(this.getHoursAndMinutes(response.data.data[j].dateTime))
                  this.rpmHistory.push(response.data.data[j].data.rpm)
                }
              }

              //New Params
              if (this.telemetryParameters[i] == 'pcb') {
                this.timeConnectionPcb = [];
                this.pcbHistory = [];
                for (var j = 0; j < response.data.data.length; j++) {
                  this.timeConnectionPcb.push(this.getHoursAndMinutes(response.data.data[j].dateTime))
                  this.pcbHistory.push(response.data.data[j].data.pcb)
                }
              }
              if (this.telemetryParameters[i] == 'pom') {
                this.timeConnectionPom = [];
                this.pomHistory = [];
                for (var j = 0; j < response.data.data.length; j++) {
                  this.timeConnectionPom.push(this.getHoursAndMinutes(response.data.data[j].dateTime))
                  this.pomHistory.push(response.data.data[j].data.pom)
                }
              }
              if (this.telemetryParameters[i] == 'ptb') {
                this.timeConnectionPtb = [];
                this.ptbHistory = [];
                for (var j = 0; j < response.data.data.length; j++) {
                  this.timeConnectionPtb.push(this.getHoursAndMinutes(response.data.data[j].dateTime))
                  this.ptbHistory.push(response.data.data[j].data.ptb)
                }
              }
              if (this.telemetryParameters[i] == 'tbt') {
                this.timeConnectionTbt = [];
                this.tbtHistory = [];
                for (var j = 0; j < response.data.data.length; j++) {
                  this.timeConnectionTbt.push(this.getHoursAndMinutes(response.data.data[j].dateTime))
                  this.tbtHistory.push(response.data.data[j].data.tbt)
                }
              }
              if (this.telemetryParameters[i] == 'kmh') {
                this.timeConnectionKmh = [];
                this.kmhHistory = [];
                for (var j = 0; j < response.data.data.length; j++) {
                  this.timeConnectionKmh.push(this.getHoursAndMinutes(response.data.data[j].dateTime))
                  this.kmhHistory.push(response.data.data[j].data.kmh)
                }
              }
              if (this.telemetryParameters[i] == 'acl') {
                this.timeConnectionAcl = [];
                this.aclHistory = [];
                for (var j = 0; j < response.data.data.length; j++) {
                  this.timeConnectionAcl.push(this.getHoursAndMinutes(response.data.data[j].dateTime))
                  this.aclHistory.push(this.tryFixDecimalNumbers(response.data.data[j].data.acl))
                }
              }
              if (this.telemetryParameters[i] == 'cgm') {
                this.timeConnectionCgm = [];
                this.cgmHistory = [];
                for (var j = 0; j < response.data.data.length; j++) {
                  this.timeConnectionCgm.push(this.getHoursAndMinutes(response.data.data[j].dateTime))
                  this.cgmHistory.push(response.data.data[j].data.cgm)
                }
              }
              if (this.telemetryParameters[i] == 'ttb') {
                this.timeConnectionTtb = [];
                this.ttbHistory = [];
                for (var j = 0; j < response.data.data.length; j++) {
                  this.timeConnectionTtb.push(this.getHoursAndMinutes(response.data.data[j].dateTime))
                  this.ttbHistory.push(response.data.data[j].data.ttb)
                }
              }
              if (this.telemetryParameters[i] == 'soc') {
                this.timeConnectionSoc = [];
                this.socHistory = [];
                for (var j = 0; j < response.data.data.length; j++) {
                  this.timeConnectionSoc.push(this.getHoursAndMinutes(response.data.data[j].dateTime))
                  this.socHistory.push(response.data.data[j].data.soc)
                }
              }
              if (this.telemetryParameters[i] == 'tpb') {
                this.timeConnectionTpb = [];
                this.tpbHistory = [];
                for (var j = 0; j < response.data.data.length; j++) {
                  this.timeConnectionTpb.push(this.getHoursAndMinutes(response.data.data[j].dateTime))
                  this.tpbHistory.push(response.data.data[j].data.tpb)
                }
              }
              if (this.telemetryParameters[i] == 'tom') {
                this.timeConnectionTom = [];
                this.tomHistory = [];
                for (var j = 0; j < response.data.data.length; j++) {
                  this.timeConnectionTom.push(this.getHoursAndMinutes(response.data.data[j].dateTime))
                  this.tomHistory.push(response.data.data[j].data.tom)
                }
              }

            } else {
              this.loadingHistory = false
              cf = true
            }
            page++

          });

        }
      });
    },

    event_tlm: function (content) {

      var panic = {
        assetId: content.assetId,
        pan: null,
      }

      if (content.data && content.data.pan != undefined) {
        panic.pan = content.data.pan
      }

      var piv = {
        assetId: content.assetId,
        inOut: content.data || null,
      }

      var asset = {
        id: content.assetId,
        lastLat: content.lat,
        lastLng: content.lng,
        lastKmh: content.kmh,
        lastPositionTime: content.deviceDateTime,
        lastWorkstatus: content.workStatus,

        lastkmh: content.lastkmh,
        hor: content.data.hor,
        hrb: content.data.hrb,
        hod: content.data.hod,
        cbc: content.data.cbc,
        tpa: content.data.tpa,
        tpt: content.data.tpt,
        toh: content.data.toh,
        mta: content.data.mta,
        mcc: content.data.mcc,
        mth: content.data.mth,
        mtt: content.data.mtt,
        btf: content.data.btf,
        cbt: content.data.cbt,
        rpm: content.data.rpm,
        cgm: content.data.cgm,

        pcb: content.data.pcb,
        pom: content.data.pom,
        ptb: content.data.ptb,
        tbt: content.data.tbt,
        acl: content.data.acl,
        kmh: content.data.kmh,

        soc: content.data.soc,
        soh: content.data.soh,
        ttb: content.data.ttb,
        tpb: content.data.tpb,
        ctr: content.data.ctr,
        tom: content.data.tom,
        dtc: content.data.dtc,
        tct: content.data.tct,
      }

      var outputs = {
        assetId: content.assetId,
        ou1: content.ou1,
        ou2: content.ou2,
        ou3: content.ou3,
        out: content.ou4
      }

      var inputs = {
        assetId: content.assetId,
        in1: content.in1,
        in2: content.in2,
        in3: content.in3,
        in4: content.in4
      }

      let block = {
        assetId: content.assetId,
        ou1: content.data.ou1
      }

      this.realtimeUpdatePan(panic);
      this.realtimeUpdateAsset(asset);
      this.realtimeUpdateOutput(outputs);
      this.realtimeUpdateInput(inputs);
      this.realtimeUpdateBlock(block)
      this.realtimeUpdatePivo(piv)

    },

    realtimeUpdateBlock(block) {
      if (!block || !block.assetId || block.ou1 == null) return
      if (router.currentRoute.params.id === block.assetId) {
        this.isBlocked = block.ou1 === 1
      }
    },


    realtimeUpdatePivo(piv) {
      if (piv == null || piv.assetId == undefined || piv.assetId == null || piv.inOut == undefined || piv.inOut == null || JSON.stringify(piv.inOut) === '{}') return

      var self = this;
      if (router.currentRoute.params.id == piv.assetId) {

        if ((piv.inOut.sse == undefined || piv.inOut.sse == null) && (piv.inOut.ssd == undefined || piv.inOut.ssd == null)) {

          this.sse = false
          this.ssd = false

          this.in1 = piv.inOut.in1
          this.in2 = piv.inOut.in2

          if (piv.inOut.ou1 != this.oldOldOut1) {
            this.out1 = piv.inOut.ou1
            this.oldOldOut1 = this.out1
          }
          if (piv.inOut.ou2 != this.oldOldOut2) {
            this.out2 = piv.inOut.ou2
            this.oldOldOut2 = this.out2
          }
          if (piv.inOut.ou3 != this.oldOldOut3) {
            this.out3 = piv.inOut.ou3
            this.oldOldOut3 = this.out3
          }

          if (piv.inOut.ou4 && piv.inOut.ou4 != this.oldInputRange01) {
            this.inputRange01 = piv.inOut.ou4
            this.oldInputRange01 = this.inputRange01
          }
          if (piv.inOut.ou1 == this.out1 && piv.inOut.ou2 == this.out2 && piv.inOut.ou3 == this.out3 && piv.inOut.ou4 == this.inputRange01) {
            this.isLoadingGlobal = false
            this.waitingCommandResponse = false
          }

        } else {
          this.ssd = piv.inOut.ssd
          this.sse = piv.inOut.sse
        }
      }
    },

    registerEventBusListeners: function () {
      this.$root.$on("tlm", this.event_tlm);
    },

    deregisterEventBusListeners: function () {
      this.$root.$off("tlm", this.event_tlm);
    },

    realtimeUpdatePan(panic) {

      if (panic == null || panic.assetId == undefined || panic.assetId == null || panic.pan == undefined || panic.pan == null) return

      var self = this;
      if (router.currentRoute.params.id == panic.assetId) {

        self.panic = panic.pan;
      }
    },

    realtimeUpdateInput: function (inputs) {

      if (inputs == null || inputs.assetId == undefined || inputs.assetId == null) return;

      var self = this;

      if (router.currentRoute.params.id == inputs.assetId) {

        if (inputs.in1) self.input01 = inputs.in1 = 1 ? true : false
        if (inputs.in2) self.input02 = inputs.in2 = 1 ? true : false
        if (inputs.in3) self.input03 = inputs.in3 = 1 ? true : false
        if (inputs.in4) self.input04 = inputs.in4 = 1 ? true : false

      }

    },

    realtimeUpdateOutput: function (outputs) {

      if (outputs == null || outputs.assetId == undefined || outputs.assetId == null) return;

      var self = this;

      if (router.currentRoute.params.id == outputs.assetId) {

        if (outputs.ou1) {
          if (outputs.ou1 == self.out1) {
            self.isLoadingOut1 = false;
            self.isLoadingGlobal = false;
          }
          self.out1 = outputs.ou1 = 1 ? true : false
        }
        if (outputs.ou2) {
          if (outputs.ou2 == self.out2) {
            self.isLoadingOut2 = false;
            self.isLoadingGlobal = false;
          }
          self.out2 = outputs.ou2 = 1 ? true : false
        }
        if (outputs.ou3) {
          if (outputs.ou3 == self.out3) {
            self.isLoadingOut3 = false;
            self.isLoadingGlobal = false;
          }
          self.out3 = outputs.ou3 = 1 ? true : false
        }

        if (outputs.ou4) self.out4 = outputs.ou4
      }

    },

    realtimeUpdateAsset: function (asset) {


      if (asset == null || asset == undefined || asset.id == undefined) return;
      var self = this;

      if (router.currentRoute.params.id == asset.id) {

        //if (asset.lastLat && asset.lastLng) self.latLong = this.verifyLatLng(asset.lastLat, asset.lastLng, self.latLong)
        if (asset.lastLat && asset.lastLng) self.latLong = this.verifyLatLng(asset.lastLat, asset.lastLng, self.latLong)
        if (asset.lastKmh) self.velocity = asset.lastKmh;
        if (asset.lastPositionTime) self.lastConnectionTime = this.formatDate(asset.lastPositionTime);

        if (asset.lastWorkstatus) this.lastWorkStatus = asset.lastWorkstatus;

        if (asset.lastkmh) self.velocity = asset.lastkmh
        if (asset.hor) self.horimeter = asset.hor
        if (asset.hrb) self.horimeterB = asset.hrb
        if (asset.hod) self.odometer = asset.hod
        if (asset.cbc) self.consumer = asset.cbc
        if (asset.tpa) self.engine = asset.tpa
        if (asset.tpt) self.transmission = asset.tpt
        if (asset.toh) self.oil = asset.toh
        if (asset.mta) self.mta = asset.mta
        if (asset.mcc) self.mcc = asset.mcc
        if (asset.mth) self.mth = asset.mth
        if (asset.mtt) self.mtt = asset.mtt
        if (asset.btf) self.btf = asset.btf
        if (asset.cbt) self.fuel = asset.cbt

        if (asset.rpm) self.rpm = asset.rpm
        if (asset.cgm) self.cgm = asset.cgm

        if (asset.pcb) self.pcb = asset.pcb
        if (asset.pom) self.pom = asset.pom
        if (asset.ptb) self.ptb = asset.ptb
        if (asset.tbt) self.tbt = asset.tbt
        if (asset.acl) self.acl = asset.acl
        if (asset.kmh) self.kmh = asset.kmh

        if (asset.soc) self.soc = asset.soc
        if (asset.soh) self.soh = asset.soh
        if (asset.ttb) self.ttb = asset.ttb
        if (asset.tpb) self.tpb = asset.tpb
        if (asset.tom) self.tom = asset.tom
        if (asset.ctr) self.ctr = asset.ctr

        if (asset.dtc) self.dtc = asset.dtc
        if (asset.tct) self.tct = asset.tct

        this.$refs.myMap.mapObject.setView(this.latLong);

      }

    },

    verifyLatLng(lat, lng, standart) {
      if (lat && lat != 0 && lng && lng != 0) return [lat, lng]
      return standart
    },


    formatDate(myDate) {

      const d = new Date(myDate);
      let dt = d.toLocaleDateString();

      const hm = d.toLocaleTimeString().substring(0, 5)

      return `${dt} - ${hm}`;

      //return `${dt} - ${d.getHours()}:${d.getMinutes()}`
    },

    getHoursAndMinutes(dateString) {
      const dt = new Date(dateString);

      const dtFormat = dt.toLocaleTimeString().substring(0, 5)

      return dtFormat;
    },

    resolveIcon: (assetType, iconUrl) => {
      if (iconUrl) return iconUrl;
      if (assetType === 1) return require('@/assets/images/assets/Car.png')
      if (assetType === 2) return require('@/assets/images/assets/Motorcycle.png')
      if (assetType === 3) return require('@/assets/images/assets/Truck.png')
      if (assetType === 4) return require('@/assets/images/assets/TruckTractor.png')
      if (assetType === 5) return require('@/assets/images/assets/Van.png')
      if (assetType === 6) return require('@/assets/images/assets/Bus.png')
      if (assetType === 7) return require('@/assets/images/assets/Pickup.png')
      if (assetType === 8) return require('@/assets/images/assets/Excavator.png')
      if (assetType === 9) return require('@/assets/images/assets/Grader.png')
      if (assetType === 10) return require('@/assets/images/assets/WheelLoader.png')
      if (assetType === 11) return require('@/assets/images/assets/Dozer.png')
      if (assetType === 12) return require('@/assets/images/assets/BackhoeLoader.png')
      if (assetType === 13) return require('@/assets/images/assets/SkidSteerLoader.png')
      if (assetType === 14) return require('@/assets/images/assets/Boat.png')
      if (assetType === 15) return require('@/assets/images/assets/JetSki.png')
      if (assetType === 16) return require('@/assets/images/assets/BoxTruck.png')
      if (assetType === 17) return require('@/assets/images/assets/MixerTruck.png')
      if (assetType === 18) return require('@/assets/images/assets/WaterTruck.png')
      if (assetType === 19) return require("@/assets/images/assets/CraneTruck.png");
      if (assetType === 20) return require("@/assets/images/assets/RoadRoller.png");
      if (assetType === 21) return require("@/assets/images/assets/ForkLift.png");
      if (assetType === 22) return require("@/assets/images/assets/ScissorLift.png");
      if (assetType === 23) return require("@/assets/images/assets/ForkLiftCabin.png");
      if (assetType === 24) return require("@/assets/images/assets/ContainerLifter.png");
      if (assetType === 25) return require("@/assets/images/assets/ArticulatedLift.png");
      if (assetType === 26) return require("@/assets/images/assets/CombineHarvester.png");
      if (assetType === 27) return require("@/assets/images/assets/SealcoatTruck.png");
      if (assetType === 28) return require("@/assets/images/assets/IrrigationPivot.png");
      if (assetType === 29) return require("@/assets/images/assets/SecurityHelmet.png");
      if (assetType === 30) return require("@/assets/images/assets/CropSprayer.png");
      if (assetType === 36) return require("@/assets/images/assets/StationaryEngine.png");
      if (assetType === 37) return require("@/assets/images/assets/DumpTruck.png");
      return require('@/assets/images/assets/Car.png')

    }

  },
  setup() {
    const ASSET_APP_STORE_MODULE_NAME = 'app-asset'
    const dts = new Date();
    dts.setHours(0);
    dts.setMinutes(0);

    const dataWorkStatus = ref([]);

    const myId = router.currentRoute.params.id

    const user = localStorage.userData != undefined ? JSON.parse(localStorage.userData) : {}

    // Register module
    if (!store.hasModule(ASSET_APP_STORE_MODULE_NAME)) store.registerModule(ASSET_APP_STORE_MODULE_NAME, assetStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ASSET_APP_STORE_MODULE_NAME)) store.unregisterModule(ASSET_APP_STORE_MODULE_NAME)
    })

    function utcToLocalDate(date) {
      // var myDate = date;
      // var offset = myDate.getTimezoneOffset() * 60 * 1000;
      //
      // var withOffset = myDate.getTime();
      // var withoutOffset = withOffset - offset;
      //
      // alert(withoutOffset)
      //
      // return withoutOffset;
      
      return date.getTime()
    }

    function resolveColor(status) {

      if (status === 'failure') return '#ea5455'
      if (status === 'off') return '#82868b'
      if (status === 'noconnection') return '#f3f2f7'
      if (status === 'severe') return '#ff9f43'
      if (status === 'iddle') return '#00cfe8'
      if (status === 'on') return '#28c76f'
      return '#28c76f'
    }

    store
        .dispatch('app-asset/fetchAssetWorkstatusHistory', {
          id: router.currentRoute.params.id,
          dt: dts.toISOString()

        })
        .then(response => {

          //dataWorkStatus.value = [];

          for (var i = ref(0); i.value < response.data.data.length; i.value++) {

            const status = ref(null);

            status.value = {
              name: response.data.data[i.value].workStatus,
              data: [{
                x: 'Status',
                y: [
                  utcToLocalDate(new Date(response.data.data[i.value].start)),
                  utcToLocalDate(new Date(response.data.data[i.value].end))
                ],

                fillColor: resolveColor(response.data.data[i.value].workStatus)

              },

              ]
            };

            dataWorkStatus.value.push(status.value);


          }


        })
        .catch((e) => {

        })

    return {
      ASSET_APP_STORE_MODULE_NAME,
      dts,
      dataWorkStatus,
      resolveColor,
      utcToLocalDate,
      myId,
      user

    }

  }

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/map-leaflet.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.someExtraClass {
  //background-color: #28c76f;
  padding: 4px;
  border-radius: 50px 50px 50px 50px;
  box-shadow: 5px 3px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: auto !important;
  height: auto !important;
}

.pulsar {
  animation: pulse-opacity 1.8s infinite;
}

@keyframes pulse-opacity {
  0%, 100% {
    box-shadow: 0 0 5px 2px rgba(255, 0, 0, 0.3);
  }
  50% {
    box-shadow: 0 0 10px 5px rgba(255, 0, 0, 0.5);
  }
}


.asset-on {
  background-color: #28c76f !important;
}

.asset-off {
  background-color: #82868b !important;
}

.asset-failure {
  background-color: #ef5258 !important;
}

.asset-noconnection {
  background-color: #f5f4f8 !important;
}

.asset-severe {
  background-color: #ff9d54 !important;
}

.asset-iddle {
  background-color: #00cfe8 !important;
}

.with-icon {
  width: 40px;
}

.vue2leaflet-map {
  &.leaflet-container {
    height: 445px;
  }
}

.text-header {
  //font-size: clamp(0.5em, 0.7em + 0.2vw, 5.5em);
  font-size: 1em;
}

//@media
@media screen and (max-width: 540px) {
  .text-header {
    font-size: 0.8em;
  }
}

@media (max-width: 576px) {
  .custom-h5 {
    font-size: 12px !important;
    /* Defina o tamanho da fonte desejado para telas sm ou menores */
  }
}
</style>
<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>
